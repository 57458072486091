import React from 'react';
import { IndexProps } from '../../templates/index-page';

// @ts-ignore
import rose from './rose.png';
import './styles.css';
import MenuButton from '../menu-button';

interface MenuProps {
  menuItems: IndexProps['markdownRemark']['frontmatter']['menuItems'];
  specials: IndexProps['markdownRemark']['frontmatter']['specials'];
  onClickContact: () => void;
  menuButtonText: string;
  menuButtonBackText: string;
  style?: React.CSSProperties;
}

const SpecialsButton = (props: {
  onClickSpecialsButton: () => void;
  text: string;
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '151px',
    }}
    className="hide-tablet"
  >
    <button
      style={{
        fontFamily: 'Bickham Script Std',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '48px',
        lineHeight: '58px',
        letterSpacing: '0.02em',
      }}
      onClick={() => props.onClickSpecialsButton()}
      className="reset-button"
    >
      {props.text}
    </button>
    <svg
      style={{
        alignSelf: 'flex-end',
      }}
      width="111"
      height="8"
      viewBox="0 0 111 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.354 4.35355C110.549 4.15829 110.549 3.84171 110.354 3.64645L107.172 0.464466C106.976 0.269204 106.66 0.269204 106.464 0.464466C106.269 0.659728 106.269 0.976311 106.464 1.17157L109.293 4L106.464 6.82843C106.269 7.02369 106.269 7.34027 106.464 7.53553C106.66 7.7308 106.976 7.7308 107.172 7.53553L110.354 4.35355ZM0 4.5H110V3.5H0V4.5Z"
        fill="black"
      />
    </svg>
  </div>
);

const BackButton = (props: { onClickBackButton: () => void; text: string }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '199px',
    }}
  >
    <button
      style={{
        fontFamily: 'Bickham Script Std',
        fontStyle: 'italic',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '29px',
      }}
      onClick={() => props.onClickBackButton()}
      className="reset-button"
    >
      {props.text}
    </button>
    <svg
      style={{
        alignSelf: 'flex-start',
      }}
      width="137"
      height="8"
      viewBox="0 0 137 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.646454 3.64645C0.451187 3.84171 0.451187 4.15829 0.646454 4.35355L3.82843 7.53553C4.0237 7.7308 4.34027 7.7308 4.53554 7.53553C4.73079 7.34027 4.73079 7.02369 4.53554 6.82843L1.70711 4L4.53554 1.17157C4.73079 0.976311 4.73079 0.659728 4.53554 0.464466C4.34027 0.269204 4.0237 0.269204 3.82843 0.464466L0.646454 3.64645ZM137 3.5L1 3.5V4.5L137 4.5V3.5Z"
        fill="black"
      />
    </svg>
  </div>
);

const MenuFooter = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      style={{
        maxHeight: '78px',
        width: '63px',
      }}
      src={rose}
      alt="rose image"
    />
  </div>
);

const MenuHeader = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px black solid',
    }}
  >
    <h1
      style={{
        fontFamily: 'Turnpike',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '20px',
      }}
      className="menu-header"
    >
      ALL DAY MENU
    </h1>
  </div>
);

const ListItems = (props: {
  menuItems: MenuProps['menuItems'];
  button: React.ReactNode;
}) => {
  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          flex: 1,
          width: '100%',
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '81%',
            height: '30px',
            position: 'absolute',
            top: '20px',
            zIndex: 1,
            display: 'grid',
            gridTemplateColumns: 'auto 30px',
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundImage:
                'linear-gradient(white, rgba(255,255,255,0.001))',
            }}
          />
          <div
            style={{
              height: '100%',
            }}
          />
        </div>
        <div
          style={{
            overflowY: 'auto',
            margin: '20px',
            width: '80%',
            position: 'relative',
          }}
          className="custom-scrollbar"
          id="fading-elements"
        >
          {props.menuItems.map((menuItem, i) => (
            <p
              key={i}
              style={{
                fontFamily: 'AT Osmose',
                textAlign: 'center',
              }}
              className="menu-items"
            >
              {menuItem}
            </p>
          ))}
        </div>
        <div
          style={{
            width: '80%',
            height: '30px',
            position: 'absolute',
            bottom: '20px',
            zIndex: 1,
            display: 'grid',
            gridTemplateColumns: 'auto 21px',
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundImage:
                'linear-gradient(rgba(255,255,255,0.001), white)',
            }}
          />
          <div
            style={{
              height: '100%',
            }}
          />
        </div>
      </div>
      {props.button}
    </>
  );
};

class Menu extends React.Component<MenuProps> {
  state = {
    switchTabs: false,
  };

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <div
        className="standalone-menu"
        style={{
          position: 'absolute',
          alignItems: 'center',
          right: '4.5%',
          top: 0,
          width: '32%',
          height: '100%',
          ...props.style,
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'grid',
            height: '100%',
            width: '100%',
            gridTemplateRows: '15% 70% 15%',
          }}
          className="all-children-bordered"
        >
          <MenuHeader></MenuHeader>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              overflowX: 'hidden',
            }}
          >
            {state.switchTabs ? (
              <ListItems
                key="specials"
                menuItems={props.specials}
                button={
                  <BackButton
                    text={props.menuButtonBackText}
                    onClickBackButton={this.onClickBackButton}
                  />
                }
              />
            ) : (
              <ListItems
                key="menu-items"
                menuItems={props.menuItems}
                button={
                  <SpecialsButton
                    text={props.menuButtonText}
                    onClickSpecialsButton={this.onClickSpecialsButton}
                  />
                }
              />
            )}
          </div>
          <MenuFooter />
        </div>
        <MenuButton onClick={props.onClickContact}>CONTACT</MenuButton>
      </div>
    );
  }
  onClickBackButton = (): void => {
    this.setState({
      switchTabs: false,
    });
  };

  /**
   * Transitions the component to show the vertical logo group component in its body.
   */
  onClickSpecialsButton = (): void => {
    this.setState({
      switchTabs: true,
    });
  };
}

export default Menu;
