import React from 'react';
import { ChildImageSharp } from '../../templates/index-page';
import './styles.css';
import PreviewCompatibleImage from '../preview-compatible-image';
import FloatingCloud from '../floating-cloud';

interface Props {
  icons: {
    image: {
      childImageSharp: ChildImageSharp;
    };
    alt: string;
    link?: string;
  }[];
}

const BrandList = (props: Props) => {
  return (
    <div
      id="brand-list"
      style={{
        display: 'flex',
        padding: '13px 0',
      }}
    >
      {props.icons.map((img, i) => (
        <div key={i} className="container">
          <FloatingCloud></FloatingCloud>

          <div
            style={{
              height: '65px',
              width: '60px',
            }}
          >
            <a href={img.link} target="_blank" rel="noopener noreferrer">
              <PreviewCompatibleImage
                className="container"
                image={img.image}
                alt={img.alt}
              />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandList;
