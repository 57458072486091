import React, { useState, useEffect } from 'react';
import FloatingCloud from '../floating-cloud';

interface Props {
  timeout: number;
}

const LoadingScreen = (props: Props) => {
  const [hide, setHide] = useState(false);
  const [dots, setDots] = useState(0);
  useEffect(() => {
    let intervalRef: any;
    let dotsNum = dots;
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        setHide(true);
      }, props.timeout);
      intervalRef = setInterval(() => {
        setDots(dotsNum++ % 3);
      }, 500);
    }
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.clearInterval(intervalRef as number);
      }
    };
  }, []);
  return hide ? null : (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          zIndex: 100,
          position: 'absolute',
        }}
        className="loading-screen"
      >
        <FloatingCloud className={' '} />
        <p
          style={{
            width: '250px',
            fontFamily: 'Bickham Script Std',
            fontSize: '40px',
            textAlign: 'center',
          }}
        >
          Good things come to those who wait
          {'.'.repeat(dots + 1) + ' '.repeat(3 - dots)}
        </p>
      </div>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <style type="text/css">
            .loading-screen {
              display: none !important;
            }
          </style>
      `,
        }}
      ></noscript>
    </React.Fragment>
  );
};

export default LoadingScreen;
