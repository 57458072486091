import React from 'react';
import Layout from '../components/layout';
import Logo from '../components/logo';
import BrandList from '../components/brand-list';
import { graphql } from 'gatsby';
import IMessage from '../components/imessage';
import Menu from '../components/menu';
import Contact from '../components/contact';

import './index.css';
import 'animate.css';
import '../fonts/stylesheet.css';

import LoadingScreen from '../components/loading-screen';
import IMessageLink from '../components/imessage-link';
import BackgroundVideo from '../components/background-video';

export class IndexPageTemplate extends React.Component<
  IndexProps['markdownRemark']['frontmatter']
> {
  state = {
    showMenu: false,
    showContact: false,
    messages: this.props.messagesList.concat(this.props.messagesList)[
      Math.floor(Math.random() * this.props.messagesList.length)
    ],
  };

  showContact = () => {
    this.setState({ showContact: true, showMenu: false });
  };

  hideContact = () => {
    this.setState({ showContact: false });
  };

  showMenu = () => {
    this.setState({ showMenu: true });
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 1024) {
        // @ts-ignore
        this.state.messages.push(<IMessageLink onClick={this.showMenu} />);
      }
    }
  }

  render() {
    const props = this.props;
    return (
      <div id="content">
        <LoadingScreen timeout={2000} />
        <BackgroundVideo />
        <Logo image={props.siteLogo.childImageSharp} alt={'Site Logo'} />
        {!this.state.showMenu && (
          <div className="center-element">
            <IMessage
              messages={this.state.messages}
              contactEmail={props.contactEmail}
              contactPhone={props.contactPhone}
              onClickContact={this.showContact}
              delay={2000}
              style={{
                display: this.state.showContact ? 'none' : undefined,
              }}
            ></IMessage>
            {this.state.showContact && (
              <Contact
                contactPhone={props.contactPhone}
                contactEmail={props.contactEmail}
                onClickMessages={this.hideContact}
              />
            )}
          </div>
        )}
        <Menu
          menuItems={props.menuItems}
          specials={props.specials}
          menuButtonText={props.menuButtonText}
          menuButtonBackText={props.menuButtonBackText}
          style={
            this.state.showMenu
              ? {
                  display: 'unset',
                  position: 'unset',
                  alignItems: 'unset',
                  right: 'unset',
                  top: 'unset',
                  width: '32%',
                  height: '411px',
                  margin: '0 auto',
                  zIndex: 10,
                }
              : undefined
          }
          onClickContact={this.showContact}
        />
        <BrandList icons={props.brandListIcons} />
      </div>
    );
  }
}

export interface IndexProps {
  markdownRemark: {
    frontmatter: {
      contactEmail: string;
      contactPhone: string;
      specials: string[];
      menuItems: string[];
      menuButtonText: string;
      menuButtonBackText: string;
      messagesList: any[];
      siteLogo: {
        childImageSharp: ChildImageSharp;
      };
      brandListIcons: {
        image: {
          childImageSharp: ChildImageSharp;
        };
        alt: string;
        link?: string;
      }[];
    };
  };
}

export interface ChildImageSharp {
  fluid: {
    aspectRatio: number;
    base64: string;
    sizes: string;
    src: string;
    srcSet: string;
  };
}

const IndexPage = ({ data }: { data: IndexProps }) => {
  return (
    <Layout>
      <IndexPageTemplate {...data.markdownRemark.frontmatter} />
    </Layout>
  );
};

export const query = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        contactEmail
        contactPhone
        specials
        menuItems
        menuButtonText
        menuButtonBackText
        messagesList
        siteLogo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brandListIcons {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          link
        }
      }
    }
  }
`;

export default IndexPage;
