import React from 'react';

const MenuButton = (props: {
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <div
    className="hide-laptop"
    style={{
      marginTop: '10px',
      width: '100%',
    }}
  >
    <button
      className="reset-button"
      style={{
        backgroundColor: 'white',
        height: '57px',
        width: '164px',
        border: '1px solid black',
        fontFamily: 'Turnpike',
        fontSize: '12px',
        lineHeight: '10px',
        display: 'block',
        margin: '0 auto',
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  </div>
);

export default MenuButton;
