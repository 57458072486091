import React from 'react';
// @ts-ignore
import cloudWEBP from './cloud-sprinkles.webp';
// @ts-ignore
import cloudGIF from './cloud-sprinkles.gif';

const FloatingCloud = (props: { className?: string }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={cloudWEBP}
      className={props.className || 'cloud-hover'}
      style={{
        width: '46px',
        height: '64px',
      }}
    />
    <img
      src={cloudGIF}
      alt="hovering cloud animation"
      className={props.className || 'cloud-hover'}
      style={{
        width: '46px',
        height: '64px',
      }}
    />
  </picture>
);

export default FloatingCloud;
