import React from 'react';
// @ts-ignore
import MilkshakeGIF from './milkshake.gif';

const IMessageLink = (props: { onClick?: () => {} }) => {
  return (
    <a
      style={{
        display: 'grid',
        gridTemplateRows: '93px 33px',
        width: '184px',
        cursor: 'pointer',
      }}
      className="hide-laptop"
      onClick={props.onClick}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          width: '184px',
        }}
      >
        <img
          style={{ objectFit: 'contain', width: '184px' }}
          src={MilkshakeGIF}
          alt={'milkshake animation'}
        />
      </div>
      <div
        style={{
          fontFamily: 'SF Pro Display, Inter, sans-serif',
          fontSize: '8px',
          fontWeight: 'bold',
          lineHeight: '10px',
        }}
      >
        Marta’s Menu - Check out some of the brands I've helped | ALL DAY MENU
        <br />
        <span
          style={{
            fontWeight: 'normal',
            color: '#87868B',
          }}
        >
          airmilkshake.com
        </span>
      </div>
    </a>
  );
};

export default IMessageLink;
